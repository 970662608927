export const setPlaylistStatus = (payload) => {
	return {
		type: 'SET_PLAYLIST_STATUS_ACTION',
		status: payload
	};
};

export const setPlaylist = (payload) => {
	return {
		type: 'SET_PLAYLIST_ACTION',
		playlist: payload
	};
};

export const setCollections = (payload) => {
	return {
		type: 'SET_COLLECTIONS_ACTION',
		collections: payload
	};
};