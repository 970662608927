import React, {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SplashComponent from './../../components/SplashComponent/SplashComponent';
import AudioTable from './../../components/AudioTable/AudioTable';

import axios from 'axios';

import { EP_PAGES,EP_HOME,URL_BEATS } from '../../../util/constants/AppConstants';
import { setAxiosConfig } from '../../../util/SetDefaultLists';

const HomeView = () => {

    const [backgroundImage,setBackgroundImage] = useState(false);
    const config = useSelector(state => state.config.config);

    const getBackgroundImage = () => {
        axios(
            setAxiosConfig(config,EP_PAGES+EP_HOME)
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setBackgroundImage(res.data.data.image);
                }else {}
            }
        ).catch(err => {})
    }

    useEffect(() => {
        if(!backgroundImage && config.name){
            getBackgroundImage();
        }
    }, [backgroundImage,config]);

    return (
        <>
            <Header />
            <SplashComponent background={backgroundImage} />
            <section className="content">
                <div className="view-container">
                    <AudioTable limit={10} />
                </div>
                <div className="view-container">
                    <span className="button">
                        <Link to={URL_BEATS}>Browse All Tracks</Link>
                    </span>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default HomeView;