import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomeView from '../app/views/Home/Home';
import AboutView from '../app/views/About/About';
import PhotosView from '../app/views/Photos/Photos';
import TracksView from '../app/views/Tracks/Tracks';
import VideosView from '../app/views/Videos/Videos';
import CollectionView from '../app/views/Collection/Collection';
import PhotoCollectionView from '../app/views/Collection/PhotoCollection';
import VideoCollectionView from '../app/views/Collection/VideoCollection';

import { URL_ABOUT, URL_HOME, URL_BEATS, EP_COLLECTION, URL_PHOTOGRAPHY, URL_VIDEOS } from './constants/AppConstants';

const AppRoutes = () => {

    return (
        <div className="main">
            <Router>
                <Routes>
                    <Route exact path={URL_HOME} element={<HomeView />} />
                    <Route exact path={URL_ABOUT} element={<AboutView />} />
                    <Route exact path={URL_BEATS} element={<TracksView />} />
                    <Route exact path={URL_PHOTOGRAPHY} element={<PhotosView />} />
                    <Route exact path={EP_COLLECTION+URL_BEATS+'/:id'} element={<CollectionView />} />
                    <Route exact path={EP_COLLECTION+URL_PHOTOGRAPHY+'/:id'} element={<PhotoCollectionView />} />
                    <Route exact path={URL_VIDEOS} element={<VideosView />} />
                    <Route exact path={EP_COLLECTION+URL_VIDEOS+'/:id'} element={<VideoCollectionView />} />
                </Routes>
            </Router>
        </div>
    )
}

export default AppRoutes;