import react from 'react';

import PhotoComponent from './parts/Photo';

const MediaTable = ({data = false}) => {

    return (
        <>
            <section className="media-table">
                <div className="view-container">
                    {
                        data.length > 0 ?
                            data.map((photo) => {
                                return <PhotoComponent src={photo.src} />;
                            }) : ''
                    }
                </div>
            </section>
        </>
    )

}

export default MediaTable;