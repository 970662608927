import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BagIcon from './../../../assets/images/icons/bag.svg';

import { LEASE, BUYOUT, EP_MEDIA, URL_INFO } from '../../../util/constants/AppConstants';

import axios from 'axios';
import { setAxiosConfig } from '../../../util/SetDefaultLists';

const CartOverlay = ({
    setSelectItem,
    cover,
    title,
    time,
    price,
    id
}) => {

    const config = useSelector(state => state.config.config);

    const [leaseAgreement,setLeaseAgreement] = useState(false);
    const [buyoutAgreement,setBuyoutAgreement] = useState(false);
    const [leaseAgreementText,setLeaseAgreementText] = useState(false);
    const [buyoutAgreementText,setBuyoutAgreementText] = useState(false);

    const handleAddToCart = () => {}

    const handleOpenAgreement = (type) => {
        if(type === LEASE)
        {
            leaseAgreement ? setLeaseAgreement(false) : setLeaseAgreement(true)
            if(!leaseAgreementText){
                axios(
                    setAxiosConfig(config,EP_MEDIA+URL_INFO+'/'+id)
                ).then(
                    res => {
                        if(res.data.status === 'success'){
                            setLeaseAgreementText(res.data.data.agreements.lease);
                        }else {}
                    }
                ).catch(err => {})
            }
        }
        if(type === BUYOUT)
        {
            buyoutAgreement ? setBuyoutAgreement(false) : setBuyoutAgreement(true)
            if(!buyoutAgreementText){

            }
        }
    }

    return (
        <div className="photo-viewer">
            <div className="view-container">
                <div className="image-view">
                    <div className="close-pv" onClick={() => setSelectItem(false)}>Close</div>
                    <div className="cart-item-container">
                        <div className="ci-left">
                            <div className="cil-left">
                                <img src={cover} />
                            </div><div className="cil-right">
                            <div className="cs-type">Track/Beat</div>
                                <h2>{title}</h2>
                                <div className="cs-info">{time}</div>
                            </div>
                        </div><div className="ci-right">
                            <div className="agreement-block">
                                <div className="ab-top">
                                    <div className="abt-left">
                                        <h3>Lease Agreement</h3>
                                    </div><div className="abt-right">
                                        <div className="cb-button" onClick={handleAddToCart}>
                                            <div className="cb-icon">
                                                <img src={BagIcon} />
                                            </div><div className="cb-price">${price}</div>
                                        </div>
                                    </div>
                                </div>
                            <div className="ab-bottom">
                                <div className="abb-text" onClick={() => handleOpenAgreement(LEASE)}>
                                    { leaseAgreement ? 'Hide Agreement' : 'View Agreement' }
                                </div>
                                {
                                    leaseAgreement ? 
                                        <div className="agreement-text">
                                            {
                                                leaseAgreementText ?
                                                    leaseAgreementText.map(line => {
                                                        return <div className="agreement-line">{line}</div>
                                                    })
                                                    : 'Loading...'
                                            }
                                        </div> : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default CartOverlay;