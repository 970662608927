import React from 'react'
import { SocialIcon } from 'react-social-icons'

const Instagram = ({url,width}) => {

    return <SocialIcon url={url} style={{width:width,height:width}} />

}

export default Instagram;
