export const createHash = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
}

export const uniqueObjects = (collection,key) => {
    return [...new Map(collection.map(item =>
        [item[key], item])).values()
    ]
}

