import react, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EP_COLLECTION, URL_PHOTOGRAPHY, URL_BEATS, EP_PHOTOS, URL_VIDEOS } from '../../../util/constants/AppConstants';

const CollectionCard = ({data,type = 'album'}) => {

    const [collectionType,setCollectionType] = useState(false);

    useEffect(() => {
        if(!collectionType){
            if(type === EP_PHOTOS){
                setCollectionType(URL_PHOTOGRAPHY);
                return;
            }
            if(type === URL_VIDEOS){
                setCollectionType(URL_VIDEOS);
                return;
            }
            setCollectionType(URL_BEATS);
        }
    }, [collectionType])

    return (
        <div className="collection">
            <Link to={EP_COLLECTION+collectionType+'/'+data.id}>
                <span>
                    <div className="collection-image">
                        <img src={data.image} />
                    </div>
                    <div className="collection-label">
                        {data.name}
                    </div>
                </span>
            </Link>
        </div>
    )

}

export default CollectionCard;