import React from 'react'
import X from './X/X'
import Instagram from './Instagram/Instagram';
import Youtube from './Youtube/Youtube';
import Default from './Default/Default';

const SocialIcons = ({type, url = '', width = '30px'}) => {

    const socialIcon = () => {
        switch(type){
            case 'twitter':
                case 'X':
                    case 'x':  
                    return <X url={url} width={width} />;
            case 'instagram':
                return <Instagram url={url} width={width} />;
            case 'youtube':
                return <Youtube url={url} width={width} />;
            default:
                return <Default url={url} width={width} />
        }
    }

    return (
        <div className='social-icon'>
            {
                socialIcon()
            }
        </div>
    )

}

export default SocialIcons;
