import React, {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';

import AudioPlayer, {
    ActiveUI,
    InterfaceGridTemplateArea,
    PlayerPlacement,
    PlayListPlacement,
    ProgressUI,
    VolumeSliderPlacement
  } from "react-modern-audio-player";

const AudioPlayerV1 = ({playlist}) => {

    const status = useSelector(state => state.playlist.status);
    const [playerStatus,setPlayerStatus] = useState(status);

    const [progressType, setProgressType] = useState("bar");
    const [playerPlacement, setPlayerPlacement] = useState("bottom-left");
    const [playListPlacement, setPlayListPlacement] = useState("right");

    const interfacePlacement = {
        trackTimeDuration: "row1-5",
        progress: "row1-4",
        playButton: "row1-6",
        repeatType: "row1-7",
        volume: "row1-8"
    };

    const [width, setWidth] = useState("100%");
    const [activeUI, setActiveUI] = useState({all:true});
    const [theme, setTheme] = useState("dark");

    useEffect(() => {
        setPlayerStatus(status);
    }, [status])

    // -- If Status is False ::
    if(!playerStatus){
      return;
    }

    return (
        <div className="audio-player v1">
            <AudioPlayer 
              playList={playlist} 
              activeUI={{
                ...activeUI,
                progress: progressType
              }}
              placement={{
                player: playerPlacement,
                playList: playListPlacement,
                interface: {
                  templateArea: interfacePlacement
                },
              }}
              rootContainerProps={{
                width
              }}    
            />
        </div>
    )
}

export default AudioPlayerV1;