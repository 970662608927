const init = {
    user_check: true,
	user_id: '',
	user_key: '',
    user_firstname: '',
    user_lastname: '',
    user_email: ''
};

export const userReducer = (state = { ...init }, action) => {
	switch (action.type) {

        case 'SET_USER_CHECK':
            return Object.assign({}, state, {
				user_check: action.user_check
			});

		case 'SET_USER_ID':
			return Object.assign({}, state, {
				user_id: action.user_id
			});

        case 'SET_USER_KEY':
            return Object.assign({}, state, {
                user_key: action.user_key
            });

        case 'SET_USER_FIRSTNAME':
            return Object.assign({}, state, {
                user_firstname: action.user_firstname
            });

        case 'SET_USER_LASTNAME':
            return Object.assign({}, state, {
                user_lastname: action.user_lastname
            });

        case 'SET_USER_EMAIL':
            return Object.assign({}, state, {
                user_email: action.user_email
            });

                




		default:
			return state;

	};
};