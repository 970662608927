export const setUserCheck = (payload) => {
	return {
		type: 'SET_USER_CHECK',
		user_check: payload
	};
};

export const setUserId = (payload) => {
	return {
		type: 'SET_USER_ID',
		user_id: payload
	};
};

export const setUserKey = (payload) => {
	return {
		type: 'SET_USER_KEY',
		user_key: payload
	};
};

export const setUserFirstName = (payload) => {
	return {
		type: 'SET_USER_FIRSTNAME',
		user_firstname: payload
	};
};

export const setUserLastName = (payload) => {
	return {
		type: 'SET_USER_LASTNAME',
		user_lastname: payload
	};
};

export const setUserEmail = (payload) => {
	return {
		type: 'SET_USER_EMAIL',
		user_email: payload
	};
};