const init = {
	session: '',
	session_id: ''
};

export const sessionReducer = (state = { ...init }, action) => {
	switch (action.type) {

		case 'SET_SESSION':
			return Object.assign({}, state, {
				session: action.session
			});

		case 'SET_SESSION_ID':
			return Object.assign({}, state, {
				session_id: action.session_id
			});

		default:
			return state;

	};
};