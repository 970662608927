/**
 *  @ These are all the app specific constants we will be using:
 */
export const SESSION_STATE = 'SESSION_STATE';
export const SESSION_ID = 'SESSION_ID';
export const USER_DATA = 'USER_DATA';
export const PLAYLIST = 'PLAYLIST_DATA';

export const LEASE = 'lease';
export const BUYOUT = 'buyout';

export const URL_HOME = '/';
export const URL_ABOUT = '/about';
export const URL_BEATS = '/beats';
export const URL_PORTFOLIO = '/portfolio';
export const URL_CONTACT = '/contact';
export const URL_PHOTOGRAPHY = '/photography';
export const URL_VIDEOS = '/videos';
export const URL_INFO = '/information';

export const EP_PLAYLIST = '/playlist';
export const EP_ALL = '/all';
export const EP_PAGES = '/pages';
export const EP_HOME = '/home';
export const EP_ABOUT = '/about';
export const EP_SEARCH = '/search';
export const EP_TERM = '/term';
export const EP_COLLECTION = '/collection';
export const EP_COLLECTIONS = '/collections';
export const EP_MEDIA = '/media';
export const EP_PHOTOS = '/photos';
export const EP_LEASE = '/' + LEASE;
export const EP_BUYOUT = '/' + BUYOUT;
export const EP_ACCOUNT = '/account';
export const EP_CREATE = '/create';
export const EP_LOGIN = '/login';
export const EP_SESSION = '/session'