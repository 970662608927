import React, {useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import axios from 'axios';

import { setAxiosConfig } from '../../../util/SetDefaultLists';
import { EP_COLLECTIONS, EP_MEDIA, EP_PHOTOS } from '../../../util/constants/AppConstants';

import MediaTable from '../../components/MediaTable/MediaTable';

const PhotoCollectionView = () => {

    const { id } = useParams();

    const config = useSelector(state => state.config.config);
    const [content,setContent] = useState(false);
    const [title,setTitle] = useState(false);
    const [image,setImage] = useState(false);
    const [totals,setTotals] = useState(0);
    const [data,setData] = useState([]);

    const getCollectionData = () => {
        axios(
            setAxiosConfig(config,EP_MEDIA+EP_PHOTOS+EP_COLLECTIONS+'/'+id)
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setTitle(res.data.data.name);
                    setImage(res.data.data.image);
                    setData(res.data.data.content);
                    setTotals(res.data.data.content.length);
                }else {}
            }
        ).catch(err => {})
    }

    useEffect(() => {
        if(!content && config.name){
            getCollectionData();
        }
    },[content,config]);

    return (
        <>
            <Header />
            {
                title ? 
                    <>
                        <section className="collection-splash">
                            <div className="view-container">
                                <div className="cs-left">
                                    <img src={image} />
                                </div><div className="cs-right">
                                    <div className="cs-type">Photo Collection</div>
                                    <h2>{title}</h2>
                                    <div className="cs-info">
                                        {
                                            totals === 1 ? totals + ' Photo' : totals + ' Photos'
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content header-space-more">
                            <div className="view-container">
                                <MediaTable data={data} />
                            </div>
                        </section>
                    </>
                    : ''
            }
            <Footer />
        </>
    )
}

export default PhotoCollectionView;