import react, {useState} from 'react';

const PhotoComponent = ({src}) => {

    const [expanded,setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(true);
    }

    const handleCloseExpand = () => {
        setExpanded(false);
    }

    return (
        <>
            <div className="collection even-space" onClick={handleExpand}>
                <span>
                    <div className="collection-image">
                        <img src={src} />
                    </div>
                </span>
            </div>
            {
                expanded ? 
                    <div className="photo-viewer">
                        <div className="view-container">
                            <div className="image-view">
                                <div className="close-pv" onClick={handleCloseExpand}>
                                    Close
                                </div>
                                <div className="image-container">
                                    <img src={src} />
                                </div>
                            </div>
                        </div>
                    </div> : ''
            }
        </>
    )

}

export default PhotoComponent;