import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { URL_ABOUT, URL_BEATS, URL_PORTFOLIO, URL_HOME, URL_CONTACT, URL_PHOTOGRAPHY, URL_VIDEOS } from '../../util/constants/AppConstants';
import SocialIcons from './SocialIcons/SocialIcons';

const Footer = () => {

    const config = useSelector(state => state.config.config);

    return (
        <>
        <footer>
            <div className="view-container">
                <div className="footer-col">
                    <h2>{config.title}</h2>
                </div><div className="footer-col">
                    <nav>
                        <ul>
                            <li>
                                <Link to={URL_HOME}>Home</Link>
                            </li>
                            {
                                config.name ?
                                <>
                                    { config.pages.about.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_ABOUT}>{config.pages.about.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    { config.pages.contact.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_CONTACT}>{config.pages.contact.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                </> : ''
                            }
                        </ul>
                    </nav>
                </div><div className="footer-col">
                    <nav>
                        <ul>
                            {
                                config.name ?
                                <>
                                    { config.pages.tracks.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_BEATS}>{config.pages.tracks.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    { config.pages.photos.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_PHOTOGRAPHY}>{config.pages.photos.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    { config.pages.portfolio.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_PORTFOLIO}>{config.pages.portfolio.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    { config.pages.video.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_VIDEOS}>{config.pages.video.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                </> : ''
                            }
                        </ul>
                    </nav>
                </div><div className="footer-col">
                    <div className="social">
                        { 
                            <>
                            <SocialIcons type={'instagram'} url={'https://instagram.com/createdbyatom'} />
                            <SocialIcons type={'x'} url={'https://x.com/createdbyatom'} /> 
                            <SocialIcons type={'youtube'} url={'https://youtube.com/createdbyatom'} /> 
                            <SocialIcons type={'tiktok'} url={'https://tiktok.com/createdbyatom'} width={'37px'} /> 
                            </>
                        }
                    </div>
                    <div className="copyright">
                        &copy; Copyright 2024, CreatedByAtom
                    </div>
                    {
                        config.credits ?
                        <>
                            <div className="copyright">
                                &nbsp;&nbsp;&bull;&nbsp;&nbsp; Powered by Audiafy <br />
                                &nbsp;&nbsp;&bull;&nbsp;&nbsp; Designed by <a href="https://creativeartisanlabs.com" target="_blank">Creative Artisan Labs</a> + <a href="https://alonzidigital.com" target="_blank">AD/</a>
                            </div>
                        </> : ''
                    }
                </div>
            </div>
        </footer>
        </>
    )

}

export default Footer;