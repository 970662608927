import React, {useState,useEffect,useRef} from 'react';
import { useSelector } from 'react-redux';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import BagIcon from './../../../../assets/images/icons/bag.svg';
import CartOverlay from '../../Cart/CartOverlay';

const AudioPlayerV2 = ({playlist}) => {

    const audioPlayer = useRef(null)

    const status = useSelector(state => state.playlist.status)
    const [playerStatus,setPlayerStatus] = useState(status)
    const [selectItem,setSelectItem] = useState(false)

    const [track,setTrack] = useState(0)
    const [source,setSource] = useState(false)
    const [title,setTitle] = useState(false)
    const [image,setImage] = useState(false)
    const [writer,setWriter] = useState(false)
    const [price,setPrice] = useState(false)
    const [time,setTime] = useState(false)
    const [id,setId] = useState(false)

    const handleUpdateTime = () => {
        let currentTime = document.getElementsByClassName('rhap_current-time')
        let totalTime = document.getElementsByClassName('rhap_total-time')
        if(!currentTime || !totalTime)
        {
            return;
        }
        currentTime = currentTime[0].innerHTML;
        totalTime = totalTime[0].innerHTML;

        currentTime = Number(currentTime.replace(':',''))
        totalTime = Number(totalTime.replace(':',''))

        if(++currentTime === totalTime)
        {
            setTimeout(function(){
                handleNextClick()
            }, 1000)
        }
    }

    const handlePlaylistStatus = () => {
        dispatch(setPlaylistStatus(true));
    }

    const handlePlaySong = (e) => {
        e.currentTarget.play()
    }

    const handleNextClick = () => {
        let nextTrack = parseInt(track+1)
        if(!playlist[nextTrack]){
            nextTrack = 0
        }
        if(playlist[nextTrack])
        {
            setTrack(nextTrack)
            setSource(playlist[nextTrack].src)
            setTitle(playlist[nextTrack].name)
            setImage(playlist[nextTrack].img)
            setWriter(playlist[nextTrack].writer)
            setPrice(playlist[nextTrack].price)
            setTime(playlist[nextTrack].length)
            setId(playlist[nextTrack].id)
        }
    }

    const handlePrevClick = () => {
        let prevTrack = parseInt(track-1)
        if(prevTrack<0)
        {
            prevTrack = parseInt(playlist.length-1)
        }
        if(playlist[prevTrack])
        {
            setTrack(prevTrack)
            setSource(playlist[prevTrack].src)
            setTitle(playlist[prevTrack].name)
            setImage(playlist[prevTrack].img)
            setWriter(playlist[prevTrack].writer)
            setTime(playlist[prevTrack].length)
            setId(playlist[prevTrack].id)
        }
    }

    useEffect(() => {
        setPlayerStatus(status);
        if(playlist[track])
        {
            setSource(playlist[track].src)
            setTitle(playlist[track].name)
            setImage(playlist[track].img)
            setWriter(playlist[track].writer)
            setPrice(playlist[track].price)
            setTime(playlist[track].length)
            setId(playlist[track].id)
        }
    }, [status,playlist])

    useEffect(() => {
        if(playerStatus){
            let nextBtn = document.getElementsByClassName('rhap_forward-button')
            if(nextBtn){
                nextBtn[0].addEventListener('click', function(){ handleNextClick() })
            }
            let prevBtn = document.getElementsByClassName('rhap_rewind-button');
            if(prevBtn){
                prevBtn[0].addEventListener('click', function(){ handlePrevClick() })
            }
        }
    }, [playerStatus,track])

    // -- If Status is False ::
    if(!playerStatus)
    {
      return;
    }

    return (
        <>
        <div className="audio-player">
            <div className="view-container">
                <div className="ap-left">
                    <div className="apl-img">
                        <img src={image} />
                    </div><div className="apl-title">
                        <span>{writer}</span>
                        <h3>{title}</h3>
                        <div className="cart-button" onClick={() => setSelectItem(true)}>
                            <div className="cb-icon">
                                <img src={BagIcon} />
                            </div><div className="cb-price">${price}</div>
                        </div>
                    </div>
                </div><div className="ap-right">
                    <AudioPlayer
                        ref={audioPlayer}
                        autoPlay
                        src={source}
                        onPlay={e => handlePlaySong(e)}
                        onListen={e => handleUpdateTime()}
                    />  
                </div>
            </div>
        </div>
        {
            selectItem ? 
                <CartOverlay setSelectItem={setSelectItem} cover={image} title={title} time={time} price={price} id={id} /> : ''
        }
        </>
    )
}

export default AudioPlayerV2;