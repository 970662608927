import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setConfig } from '../datastore/actions/configActions';

import configurations from './../config.json';

import axios from 'axios';
import { setAxiosConfig } from './SetDefaultLists';
import { setCollections, setPlaylist,setPlaylistStatus } from './../datastore/actions/playlistActions';

import { EP_PLAYLIST, EP_ALL, SESSION_STATE, SESSION_ID, EP_ACCOUNT, EP_SESSION } from './constants/AppConstants';
import { onlyUnique } from './HelperFunctions';

import { setUserEmail, setUserFirstName, setUserLastName, setUserId, setUserKey, setUserCheck } from '../datastore/actions/userActions';

const Preload = () => {

    const dispatch = useDispatch();

    const session = useSelector(state => state.session.session);
    const config = useSelector(state => state.config.config);
    const settings = useSelector(state => state.settings);
    const playlist = useSelector(state => state.playlist.playlist);
    const collections = useSelector(state => state.playlist.collections);

    useEffect(() => {
        if(!config.length){
            dispatch(setConfig(configurations));
            if(playlist.length === 0)
            {
                axios(
                    setAxiosConfig(configurations,EP_PLAYLIST+EP_ALL)
                ).then(
                    res => {
                        if(res.data.status === 'success')
                        {
                            let playlistData = res.data.data;
                            let collectionsData = [];
                            playlistData.map((item) => {
                                collectionsData.push({id:item.writer_id,name:item.writer});
                            });
                            collectionsData = collectionsData.filter(onlyUnique);
                            dispatch(setCollections(collectionsData));
                            dispatch(setPlaylist(playlistData));
                            //dispatch(setPlaylistStatus(true));
                        }
                        else {}
                    }
                ).catch(err => {})
            }
        }

        let sessionState = localStorage.getItem(SESSION_STATE);
        let sessionStateId = localStorage.getItem(SESSION_ID);

        if(sessionState)
        {
            axios(
                setAxiosConfig(configurations, EP_ACCOUNT+EP_SESSION + '/?user_token=' + sessionState + '&user_id=' + sessionStateId)
            ).then(
                res => {
                    if(res.data.status === 'success')
                    {
                        let data = res.data.data;
                        dispatch(setUserCheck(false))
                        dispatch(setUserEmail(data.user_email))
                        dispatch(setUserId(data.user_id))
                        dispatch(setUserFirstName(data.user_firstname))
                        dispatch(setUserLastName(data.user_lastname))
                        dispatch(setUserKey(data.user_key))
                    }
                    else {}
                }
            ).catch(err => {})
        }else {
            dispatch(setUserCheck(false))
        }


    }, [session,config,settings,playlist]);

    return;
}

export default Preload;