const init = {
	cart: []
};

export const cartReducer = (state = { ...init }, action) => {
	switch (action.type) {

		case 'SET_CART_ACTION':
			return Object.assign({}, state, {
				cart: action.playlist
			});

		default:
			return state;

	};
};