const init = {
	status: false,
	playlist: [],
	collections: []
};

export const playlistReducer = (state = { ...init }, action) => {
	switch (action.type) {

		case 'SET_PLAYLIST_STATUS_ACTION':
			return Object.assign({}, state, {
				status: action.status
			});

		case 'SET_PLAYLIST_ACTION':
			return Object.assign({}, state, {
				playlist: action.playlist
			});

		case 'SET_COLLECTIONS_ACTION':
			return Object.assign({}, state, {
				collections: action.collections
			});

		default:
			return state;

	};
};