import react from 'react'

const InputItem = ({label,value,setItem,setError = false,type = 'input'}) => {

    return (
        <>
            <div className={setError ? "input-item error" : "input-item"}>
                <div className="ii-label">
                    {label}
                </div>
                <div className="ii-input">
                    <input type={ type === 'password' ? 'password' : 'text'} onChange={(e) => setItem(e.currentTarget.value)} value={value} />
                </div>
            </div>
        </>
    )

}

export default InputItem;