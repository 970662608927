import React from 'react';

const SplashComponent = ({background}) => {

    return (
        <>
            <section className="splash-component">
                {
                    background ?
                        <div className="sc-background" style={{backgroundImage:'url(' + background + ')'}}>
                            <div className="fade-gradient"></div>
                        </div> : ''
                }
            </section>
        </>
    )
}

export default SplashComponent;