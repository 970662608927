import React, {useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { 
    URL_ABOUT, 
    URL_BEATS, 
    URL_PORTFOLIO, 
    URL_HOME, 
    URL_PHOTOGRAPHY, 
    EP_ACCOUNT, 
    EP_CREATE, 
    SESSION_STATE, 
    SESSION_ID ,
    EP_LOGIN,
    URL_VIDEOS,
    URL_CONTACT
} from '../../util/constants/AppConstants';

import BagIcon from './../../assets/images/icons/bag.svg';
import LoginIcon from './../../assets/images/icons/user-login.svg';
import InputItem from './Input/InputItem';
import SubmitItem from './Input/SubmitItem';

import axios from 'axios';

import { setAxiosConfig } from '../../util/SetDefaultLists';

import { setUserEmail,setUserId,setUserFirstName,setUserLastName,setUserKey } from '../../datastore/actions/userActions';

const Header = () => {

    const dispatch = useDispatch();

    const cartTotal = useState('0.00');

    const config = useSelector(state => state.config.config);
    const user = useSelector(state => state.user);
    const [menu,setMenu] = useState(false);
    const [cart,setCart] = useState(false);

    const [errorStatement,setErrorStatement] = useState(false);

    const [email,setEmail] = useState(null);
    const [password,setPassword] = useState(null);
    const [firstName,setFirstName] = useState(null);
    const [lastName,setLastName] = useState(null);

    const [createAccount,setCreateAccount] = useState(false);
    const [creatingAccount,setCreatingAccount] = useState(false);

    const [loggingInAccount,setLoggingInAccount] = useState(false);

    const [emailError,setEmailError] = useState(false);
    const [passwordError,setPasswordError] = useState(false);
    const [firstNameError,setFirstNameError] = useState(false);
    const [lastNameError,setLastNameError] = useState(false);

    const handleUserMenu = () => {
        setMenu(true)
    }

    const handleLogin = () => {
        setMenu(true)
    }

    const handleCart = () => {
        setCart(true)
    }

    const handleLogout = () => {
        dispatch(setUserEmail(null))
        dispatch(setUserId(null))
        dispatch(setUserFirstName(null))
        dispatch(setUserLastName(null))
        dispatch(setUserKey(null))
        localStorage.setItem(SESSION_ID,'')
        localStorage.setItem(SESSION_STATE,'')
        setMenu(false)
    }

    const handleSetError = () => {
        var errors = false;
        if(!email || email === ""){
            setEmailError(true);
            errors = true;
        }else {
            setEmailError(false);
        }
        if(!password || password === ""){
            setPasswordError(true);
            errors = true;
        }else {
            setPasswordError(false);
        }
        if(!firstName || firstName === ""){
            setFirstNameError(true);
            errors = true;
        }else {
            setFirstNameError(false);
        }
        if(!lastName || lastName === ""){
            setLastNameError(true);
            errors = true;
        }else {
            setLastNameError(false);
        }
        return errors;
    }

    const handleSetLoginError = () => {
        var errors = false;
        if(!email || email === ""){
            setEmailError(true);
            errors = true;
        }else {
            setEmailError(false);
        }
        if(!password || password === ""){
            setPasswordError(true);
            errors = true;
        }else {
            setPasswordError(false);
        }
        return errors;
    }


    const handleCreateAccount = () => {
        if(emailError || passwordError || firstNameError || lastNameError){
            return;
        }
        setCreatingAccount(true);
        axios(
            setAxiosConfig(config, EP_ACCOUNT+EP_CREATE, 'post', {
                email: email,
                password: password,
                firstname: firstName,
                lastname: lastName
            })
        ).then(
            res => {
                if(res.data.status === 'success')
                {
                    setMenu(false)
                    setCreatingAccount(false)

                    let data = res.data.data;
                    dispatch(setUserEmail(data.user_email))
                    dispatch(setUserId(data.user_id))
                    dispatch(setUserFirstName(data.user_firstname))
                    dispatch(setUserLastName(data.user_lastname))
                    dispatch(setUserKey(data.user_key))

                    localStorage.setItem(SESSION_ID,data.user_id)
                    localStorage.setItem(SESSION_STATE,data.user_key)

                }else {}
            }
        ).catch(err => {})
    }

    const handleLoginAccount = () => {
        if(emailError || passwordError){
            return;
        }
        setLoggingInAccount(true)
        setErrorStatement(false)
        axios(
            setAxiosConfig(config, EP_ACCOUNT+EP_LOGIN, 'post', {
                email: email,
                password: password
            })
        ).then(
            res => {
                if(res.data.status === 'success')
                {
                    if(!res.data.data.error_statement){

                        setErrorStatement(false)

                        setMenu(false)
                        setLoggingInAccount(false)

                        let data = res.data.data;
                        dispatch(setUserEmail(data.user_email))
                        dispatch(setUserId(data.user_id))
                        dispatch(setUserFirstName(data.user_firstname))
                        dispatch(setUserLastName(data.user_lastname))
                        dispatch(setUserKey(data.user_key))

                        localStorage.setItem(SESSION_ID,data.user_id)
                        localStorage.setItem(SESSION_STATE,data.user_key)
                    }else {
                        setErrorStatement(res.data.data.error_statement)
                        setLoggingInAccount(false)
                    }

                }else {}
            }
        ).catch(err => {})


    }


    return (
        <>
        <header>
            <div className="logo">
                <h1><Link to={URL_HOME}>{config.title}</Link></h1>
            </div><div className="menu-cart">
                <div className="menu">
                    <nav>
                        <ul>
                            {
                                config.name ?
                                <>
                                    {
                                        config.pages.about.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_ABOUT}>{config.pages.about.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    {
                                        config.pages.tracks.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_BEATS}>{config.pages.tracks.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    {
                                        config.pages.photos.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_PHOTOGRAPHY}>{config.pages.photos.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    {
                                        config.pages.portfolio.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_PORTFOLIO}>{config.pages.portfolio.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                    {
                                        config.pages.video.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_VIDEOS}>{config.pages.video.label}</Link>
                                            </li>
                                        </> : ''
                                    }{
                                        config.pages.contact.status ? 
                                        <>
                                            <li>
                                                <Link to={URL_CONTACT}>{config.pages.contact.label}</Link>
                                            </li>
                                        </> : ''
                                    }
                                </> : ''
                            }
                            <li className="mobile">
                                <span>Menu</span>
                            </li>
                        </ul>
                    </nav>
                    
                </div><div className="cart" onClick={user && user.user_id ? handleCart : handleLogin}>
                    <img src={BagIcon} /><span>${cartTotal}</span>
                </div><div className="cart" onClick={user && user.user_id ? handleUserMenu : handleLogin}>
                    {
                        !user.user_check ? 
                            <>
                                <img src={LoginIcon} /><span>{
                                    user && user.user_id ? user.user_firstname + ' ' + user.user_lastname.charAt(0) : 'Login'
                                }</span>
                            </> : <div className='th-message no-margin'>Loading...</div>
                    }
                </div>
            </div>
        </header>
        {
            cart ? 
                <div className="photo-viewer">
                    <div className="view-container">
                        <div className="image-view">
                            <div className="close-pv" onClick={() => setCart(false)}>
                                Close
                            </div>
                            <div className="cart-item-container"></div>
                        </div>
                    </div>
                </div>
            
            : ''
        }
        {
            menu ? 
                <div className="photo-viewer account-container">
                    <div className="view-container">
                        <div className="image-view">
                            <div className="close-pv" onClick={() => setMenu(false)}>
                                Close
                            </div>
                            <div className="cart-item-container">
                                {
                                    user && user.user_id ?
                                    <>
                                        <div className="cs-type">
                                            {user.user_firstname + ' ' + user.user_lastname}
                                        </div>
                                        <h2>Account Information</h2>

                                        <div className="input-item center">
                                            <div className="ii-input">
                                                <span className="button" onClick={handleLogout}>Logout</span>
                                            </div>
                                        </div>
                                    </>
                                        
                                        : 
                                        
                                        <>
                                        {
                                            createAccount ? 
                                            <>                                                
                                               
                                                <div className="cs-type">
                                                    Get All Access to Orders/Features
                                                </div>
                                                <h2>Create An Account</h2>
                                                <div className="cs-info">
                                                    Already Have An Account? <span onClick={() => setCreateAccount(false)}>Login</span>
                                                </div>
                                                <div className="cs-form">
                                                    {
                                                        !creatingAccount ?
                                                            <>
                                                                <InputItem label={'Email'} value={email} setItem={setEmail} setError={emailError} />
                                                                <InputItem label={'Password'} value={password} type={'password'} setItem={setPassword} setError={passwordError} />
                                                                <InputItem label={'First Name'} value={firstName} setItem={setFirstName} setError={firstNameError} />
                                                                <InputItem label={'Last Name'} value={lastName} setItem={setLastName} setError={lastNameError} />
                                                                <SubmitItem label={'Create Account'} onSubmit={handleCreateAccount} setError={handleSetError} />
                                                            </>
                                                            : 
                                                            <div className='th-message'>Creating account, please wait...</div> 
                                                        }
                                                 </div>
                                                
                                            </> 
                                                
                                                
                                                
                                                : 

                                            <>                                                
                                               
                                                    <div className="cs-type">
                                                        Continue to your account
                                                    </div>
                                                    <h2>Login</h2>
                                                    <div className="cs-info">
                                                        Don't have an account? <span onClick={() => setCreateAccount(true)}>Create One</span>
                                                    </div>

                                                    {
                                                        errorStatement ? 
                                                            <div className="error-statement">{errorStatement}</div> : ''
                                                    }

                                                    <div className="cs-form">
                                                    {
                                                        !loggingInAccount ?
                                                            <>
                                                                <InputItem label={'Email'} value={email} setItem={setEmail} setError={emailError} />
                                                                <InputItem label={'Password'} value={password} type={'password'} setItem={setPassword} setError={passwordError} />
                                                                <SubmitItem label={'Login'} onSubmit={handleLoginAccount} setError={handleSetLoginError} />
                                                            </>
                                                            : 
                                                            <div className='th-message'>Logging in, please wait...</div> 
                                                        }
                                                 </div>
                                                
                                            </>

                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div> : ''
        }
        </>
    )

}

export default Header;