import React, {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';

import TableRow from './parts/TableRow';
import { EP_PLAYLIST, EP_SEARCH, EP_TERM, EP_COLLECTION } from '../../../util/constants/AppConstants';

import axios from 'axios';

import { uniqueObjects } from '../../../util/HelperFunctions';

import { setAxiosConfig } from '../../../util/SetDefaultLists';

const AudioTable = ({limit = 0, searchable = false, filterable = false, data = false, searchTxt = false, messageTxt = false}) => {

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const config = useSelector(state => state.config.config);
    const playlist = useSelector(state => state.playlist.playlist);
    const collections = useSelector(state => state.playlist.collections);

    const [searchLabel,setSearchLabel] = useState('Searching...');
    const [message,setMessage] = useState('No results found for that search term.');

    const [items,setItems] = useState(null);
    const [collectionsItems,setCollectionsItems] = useState(null);
    const [searchTerm,setSearchTerm] = useState(null);
    const [searching,setSearching] = useState(false);
    const [loaded,setLoaded] = useState(false);

    const handleCollectionSearch = async (e) => {
        const value = e.currentTarget.value;
        setSearching(true);
        axios(
            setAxiosConfig(config,EP_PLAYLIST+EP_SEARCH+EP_COLLECTION+'/'+value)
        ).then(
            res => {
                if(res.data.status === 'success')
                {
                    setItems(res.data.data);
                    setSearching(false);
                }else {}
            }
        ).catch(err => {})
    }

    const handleSearch = async (e) => {
        const value = e.currentTarget.value;
        setSearching(true);
        await sleep(500);
        axios(
            setAxiosConfig(config,EP_PLAYLIST+EP_SEARCH+EP_TERM+'/'+value)
        ).then(
            res => {
                if(res.data.status === 'success')
                {
                    setItems(res.data.data);
                    setSearching(false);
                }else {}
            }
        ).catch(err => {})
    }

    useEffect(() => {
        if(searchTxt){
            setSearchLabel(searchTxt);
        }
        if(messageTxt){
            setMessage(messageTxt);
        }
    }, [searchTxt,messageTxt]);

    useEffect(() => {
        if(config.name && (!items || loaded === false) && playlist.length > 0 && !data)
        {
            let playlistItems = playlist;
            if(limit>0){
                playlistItems = playlist.slice(0,limit);
            }
            setItems(playlistItems);
            setCollectionsItems(uniqueObjects(collections,'id'));
            setLoaded(true);
        }
        if(data){
            setItems(data);
        }
    }, [config,items,playlist,limit,loaded,collections,data]);

    return (
        <>
        {
            filterable ? 
                <div className="filters">
                    <select class="filter-select" onChange={handleCollectionSearch}>
                        <option value="all">Collection/Album</option>
                        {
                            collectionsItems && collectionsItems.length > 0 ?
                                collectionsItems.map((item) => {
                                    return <option value={item.id}>{item.name}</option>
                                }) : ''
                        }
                    </select>
                </div> : ''
        }
        {
            searchable ? 
                <input type="text" class="search" value={searchTerm} onChange={handleSearch} placeholder="Search" />
                : ''
        }
        <div className="table">
            <div className="table-header">
                <div className="th-img"></div><div className="th-title">
                    Title
                </div><div className="th-time">
                    Time
                </div><div className="th-bpm">
                    Bpm
                </div><div className="th-tags">
                    Tags
                </div><div className="th-button"></div>
            </div>
            {
                searching ? 
                    <TableRow empty={true} message={searchLabel} />
                : 
                    items ?
                        items.length > 0 ?
                            items.map((item) => {
                                return <TableRow id={item.id} cover={item.img} title={item.name} time={item.length} bpm={item.bpm} tags={item.tags} price={item.price} playlist={playlist} />
                            }) : 
                            <TableRow empty={true} message={message} />
                            :  
                            <TableRow empty={true} message={'Loading...'} />
            }
        </div>
        </>
    )

}

export default AudioTable;