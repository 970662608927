import React, {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import Preload from './util/Preload';
import AppRoutes from './util/Routes';

import AudioPlayerComponent from './app/components/AudioPlayer/AudioPlayerComponent';

function App() {

  const playlist = useSelector(state => state.playlist.playlist);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    if(playlist.length > 0){
      setLoading(false);
    }
  }, [loading,playlist])
  
  return (
    <div className="App">
      <Preload />
      <AppRoutes />
      {
         playlist.length > 0 && !loading ? <AudioPlayerComponent playlist={playlist} /> : ''
      }
    </div>
  );
}

export default App;
