import { combineReducers } from 'redux';
import { configReducer } from './configReducer';
import { sessionReducer } from './sessionReducer';
import { actionReducer } from './actionReducer';
import { settingsReducer } from './settingsReducer';
import { playlistReducer } from './playlistReducer';
import { cartReducer } from './cartReducer';
import { userReducer } from './userReducer';

export default combineReducers({
    action: actionReducer,
    config: configReducer,
    session: sessionReducer,
    settings: settingsReducer,
    playlist: playlistReducer,
    cart: cartReducer,
    user: userReducer
})