const init = {
	loggingIn: false,
	notify: {
		status: false,
		type: null,
		title: '',
		message: ''
	}
};

export const actionReducer = (state = { ...init }, action) => {
	switch (action.type) {

		case 'SET_LOGIN_ACTION':
			return Object.assign({}, state, {
				loggingIn: action.loggingIn
			});

			case 'SET_NOTIFY_ACTION':
				return Object.assign({}, state, {
					notify: action.notify
				});

		default:
			return state;

	};
};