import React, {useEffect,useState} from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import axios from 'axios';

import { setAxiosConfig } from '../../../util/SetDefaultLists';

import { EP_MEDIA,URL_PHOTOGRAPHY,EP_COLLECTIONS,EP_PAGES,EP_PHOTOS} from '../../../util/constants/AppConstants';

import CollectionCard from '../../components/CollectionCard/CollectionCard';

const PhotosView = () => {

    const config = useSelector(state => state.config.config);
    const [content,setContent] = useState(false);
    const [title,setTitle] = useState(false);
    const [collections,setCollections] = useState(false);

    const getPageContent = () => {
        axios(
            setAxiosConfig(config,EP_PAGES+URL_PHOTOGRAPHY)
        ).then(
            res => {
                if(res.data.status === 'success'){
                    setTitle(res.data.data.name);
                    setContent(res.data.data.content);
                }else {}
            }
        ).catch(err => {})
    }

    const getCollections = () => {
        axios(
            setAxiosConfig(config,EP_MEDIA+EP_PHOTOS+EP_COLLECTIONS)
        ).then(
            res => {
                if(res.data.status === 'success')
                {
                    setCollections(res.data.data);
                }
                else {}
            }
        );
    }

    useEffect(() => {
        if(!content && config.name){
            getPageContent();
            getCollections();
        }
    },[content,config]);

    return (
        <>
            <Header />
            {
                content ? 
                    <>
                        <section className="content-title">
                            <h2>{title}</h2>
                        </section>
                        <section className="content">
                            <div className="view-container">
                            {
                                content.length > 0 ?
                                    content.map((line) => {
                                        return <div className="line">{line}</div>
                                    }) : ''
                            }
                            </div>
                        </section>
                        <section className="content-title">
                            <div className="view-container">
                                <div className="discography">
                                    {
                                        collections && collections.length > 0 ?
                                            collections.map((collection) => {
                                                return <CollectionCard data={collection} type={EP_PHOTOS} />
                                            }) : ''
                                    }
                                </div>
                            </div>
                        </section>
                    </>
                    : ''
            }
            <Footer />
        </>
    )
}

export default PhotosView;