import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tags from './Tags';
import BagIcon from './../../../../assets/images/icons/bag.svg';

import { setPlaylistStatus, setPlaylist } from '../../../../datastore/actions/playlistActions';

import { LEASE, BUYOUT } from '../../../../util/constants/AppConstants';

import axios from 'axios';
import { setAxiosConfig } from '../../../../util/SetDefaultLists';
import CartOverlay from '../../Cart/CartOverlay';

const TableRow = ({id,cover,title,time,bpm,tags,price,empty = false,message = '',playlist=false}) => {

    const dispatch = useDispatch();

    const config = useSelector(state => state.config.config);

    const [selectItem,setSelectItem] = useState(false);

    const handlePlaylistStatus = () => {
        if(playlist)
        {
            dispatch(setPlaylist(playlist));
        }
        dispatch(setPlaylistStatus(true));
    }

    return (
        <div key={id} className='table-row'>
            {
                empty ? 
                    <div className='th-message'>{message}</div> 
            : <>
                    <div className="th-img">
                        <img src={cover} />    
                    </div><div className="th-title" onClick={handlePlaylistStatus}>
                        {title}
                    </div><div className="th-time">
                        {time}
                    </div><div className="th-bpm">
                        {bpm}
                    </div><div className="th-tags">
                        {
                            tags.length > 0 ? 
                                tags.map((tag) => {
                                    return <Tags tag={tag} />
                                }) : ''
                        }
                    </div><div className="th-button">
                        <div className="cart-button" onClick={() => setSelectItem(true)}>
                            <div className="cb-icon">
                                <img src={BagIcon} />
                            </div><div className="cb-price">${price}</div>
                        </div>
                    </div>
                </>
            }
             {
                selectItem ? 
                    <CartOverlay setSelectItem={setSelectItem} id={id} cover={cover} title={title} time={time} price={price} /> : ''
            }
        </div>
        
    );

}

export default TableRow;