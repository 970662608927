const init = {
	config: []
};

export const configReducer = (state = { ...init }, action) => {
	switch (action.type) {

		case 'SET_CONFIG':
			return Object.assign({}, state, {
				config: action.config
			});

		default:
			return state;

	};
};