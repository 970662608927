import allReducers from './reducers/index';
import { loadState, saveState } from '../util/LocalStorage';
import { createStore } from 'redux';
import config from './../config.json';

const persistedState = loadState();
let store = null;

if ( config.env === 'dev') {
    store = createStore(
        allReducers,
        persistedState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    store = createStore(
        allReducers,
        persistedState
    );
}

export default store;