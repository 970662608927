import React, {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AudioTable from './../../components/AudioTable/AudioTable';

import axios from 'axios';

import { EP_PAGES,EP_HOME } from '../../../util/constants/AppConstants';
import { setAxiosConfig } from '../../../util/SetDefaultLists';

const TracksView = () => {

    const config = useSelector(state => state.config.config);

    return (
        <>
            <Header />
            <section className="content-title">
                <h2>Beats</h2>
            </section>
            <section className="content header-space">
                <div className="view-container">
                    <AudioTable searchable={true} filterable={true} />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TracksView;