import React, {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';

import AudioPlayerV1 from './v1/AudioPlayerV1';
import AudioPlayerV2 from './v2/AudioPlayerV2';

const AudioPlayerComponent = ({playlist}) => {

    const config = useSelector(state => state.config.config);
    const [player,setPlayer] = useState(false);

    useEffect(() => {
      if(config && !player)
      {
        setPlayer(config.player);
      }
    });

    if(!player){
      return;
    }

    return (
      <>
        { player === 2 ? <AudioPlayerV2 playlist={playlist} /> : <AudioPlayerV1 playlist={playlist} /> }
      </>
    )
}

export default AudioPlayerComponent;