import react from 'react'

const SubmitItem = ({label,onSubmit,setError}) => {

    const handleSetError = () => {
        if(!setError()){
            onSubmit();
        }
    }

    return (
        <>
            <div className="input-item">
                <div className="ii-input">
                    <span className="button" onClick={handleSetError}>{label}</span>
                </div>
            </div>
        </>
    )

}

export default SubmitItem;